import React, { useEffect, useState } from 'react';
import { DialogActions, TextField, Box, Switch, Alert, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import Swal from 'sweetalert2'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../_helper/Route/Url';

export default function AddUser({ refreshData, isUpdate }) {

    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        id:undefined,
        email: '',
        password: '',
        name: '',
        status: true,
    });
    const [formErrors, setFormErrors] = useState({
        id:undefined,
        requestError: "",
        email: '',
        password: '',
        name: '',
        status: '',
    });

    const handleChange = (field, value) => {
        if (value.length > 25) return;
        setFormValues({
            ...formValues,
            [field]: value,
        });
        setFormErrors({
            ...formErrors,
            [field]: '',
        });
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({
            id:undefined,
            email: '',
            name: '',
            password: '',
            status: true,
        });
        setFormErrors({
            id:undefined,
            requestError: "",
            email: '',
            password: '',
            name: '',
            status: '',
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const fetchUser = async () => {
        const id = isUpdate;
        try {
            const response = await fetch(url + '/api/sup/user/get?id=' + id);
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                const data = await response.json();
                console.log(data)
                setFormValues(data);
                setFormErrors({ requestError: '' });
            }
        } catch (error) {
            setFormErrors({ requestError: 'Failed to fetch Role' });
        }
    };

    useEffect(() => {
        if (open && isUpdate) {
            fetchUser();
        }
    }, [open, isUpdate]);

    const validate = () => {
        const errors = {};
        if (!formValues.name) errors.name = 'Name is required';
        if (!formValues.status) errors.status = 'Status is required';
        if (formValues.email.length > 25) errors.email = 'Max Characters 25';
        if (formValues.password.length > 25 ) errors.password = 'Max Characters 25';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        //console.log(isUpdate)
        try {
            const response = await fetch( url + "/api/sup/user/add", {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(formValues),
            });
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                handleClose();
                setFormValues({
                    id:undefined,
                    email: '',
                    name: '',
                    password: '',
                    status: true,
                });
                Swal.fire({
                    position: "center",
                    icon: "success",
                    html: `
        <div class="custom-content">
            Status has been changed
        </div>
        <style>
            .swal2-popup {
                padding: 0px !important; /* Reduce padding inside the Swal box */
            }
            .swal2-icon.swal2-success {
                font-size: 11px !important; /* Adjust the size as needed */
            }
            .swal2-title {
                font-size: 18px !important; /* Adjust the size as needed */
            }
            .custom-content {
                font-size: 14px !important; /* Adjust the size as needed */
            }
        </style>
    `,
                    customClass: {
                        icon: 'swal2-icon',
                        title: 'swal2-title',
                        content: 'custom-content'
                    },
                    title: isUpdate ? "User Updated Successfully" : "User Created Successfully",
                    showConfirmButton: false,
                    timer: 2000
                });
                refreshData();
            }
        } catch (error) {
            isUpdate ?
                setFormErrors({ requestError: "Failed to Update User" })
                :
                setFormErrors({ requestError: "Failed to Add User" })
        }
    };

    return (
        <>
            {isUpdate ?
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={handleClickOpen} style={{ marginRight: "15px", width: "20px", height: "20px", color: "#0b0ff1c7" }} text />
                :
                <Button icon="pi pi-plus" label="Add User" onClick={handleClickOpen} style={{ marginRight: "10", height: "35px", padding: "3px", backgroundColor: "#0b0ff1c7", borderColor: "#0b0ff1c7" }} raised />
            }

            <Dialog
                visible={open}
                onHide={handleClose}
                style={{ minWidth: "58vw" }}
                header={isUpdate ? "Update User" : "Add User"}
            >
                <Box sx={{ padding: 2, paddingTop: 1 }}>
                    {formErrors.requestError && <Alert sx={{ marginBottom: 3 }} severity="error">{formErrors.requestError}</Alert>}
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        sx={{width:"25%"}}
                        value={formValues.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        type='email'
                        label="Username"
                        sx={{width:"25%", ml:2}}
                        value={formValues.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        type='password'
                        label="Password"
                        sx={{width:"25%", ml:2}}
                        value={formValues.password}
                        onChange={(e) => handleChange('password', e.target.value)}
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <FormControl
                        sx={{ ml: 2, width: "20%", ml:2 }}
                        error={!!formErrors.status}
                    >
                        <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="status-label">
                            Status
                        </InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            name="status"
                            value={formValues.status}
                            onChange={(e) => handleChange('status', e.target.value)}
                            label="Status"
                            sx={{
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        fontSize: '13px',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>Select Status</em>
                            </MenuItem>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                            {/* Add more options as needed */}
                        </Select>
                        <FormHelperText sx={{ fontSize: '12px' }}>
                            {formErrors.status}
                        </FormHelperText>
                    </FormControl>
                </Box>
                <DialogActions>
                    <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                    <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{isUpdate ? "Update" : "Create"}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
