import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { DialogActions, TextField, Box, InputLabel, FormControl, FormHelperText, Select, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';


const initialFormData = {
    id: '',
    username: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobile: '',
    prefix: '',
    roleId: '',
    role: '',
    permission: '',
    details: '',
    aadharImage: null,
    licenseImage: null,
    dateOfBirth:''
};

export default function AddUser({ hideDialog, editUserId, setEditUserId, setUserDialog, refreshData }) {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [RIdOptions, setRIdOptions] = useState();
    const [customers, setCustomers] = useState();


    useEffect(() => {
        // if (editUserId) {
        //     fetchUserData();
        // }
    }, [editUserId]);

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/users/getUserById?id=${editUserId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch user data');

            const userData = await response.json();
            console.log(userData)
            setFormData(userData);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch user data', life: 3000 });
        }
    };

    const fetchAllRoles = () => {
        fetch(url + '/api/sup/role/getAll').then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setCustomers(result)
                    setRIdOptions(result);
                    console.log(result)
                })
            }
        })
    }

    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleImageChange = (e, field) => {
        const file = e.target.files[0];
        setFormData({ ...formData, [field]: file });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.username) newErrors.username = "Username is required";
        if (!formData.email) newErrors.email = "Email is required";
        // Additional validations as needed...
        return newErrors;
    };

    const handleRIdChange = (e) => {
        console.log(e.value)
        setFormData({ ...formData, roleId: e.value });
    };

    const handleClose = () => {
        setVisible(false);
        setEditUserId(null);
        setFormData(initialFormData);
        setErrors({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("sdifjkgs")
        const formErrors = validateForm();
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;
        console.log(formData)
        const formDataToSend = new FormData();
        Object.entries({ ...formData }).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });
        try {
            const method = 'POST';
            const response = await fetch(`${url}/api/admin/users/addUser`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formDataToSend,
            });

            if (response.ok) {
                console.log("first")
                const data = await response.json();
                console.log(data);
                toast.current.show({ severity: 'success', summary: 'Success', detail: data.message, life: 3000 });
                refreshData();
                handleClose();
            }
            else {
                const errorData = await response.json();
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorData.message || 'An error occurred', life: 3000 });
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred', life: 3000 });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            {editUserId ?
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={() => { setVisible(true); fetchUserData(); fetchAllRoles() }} style={{ marginRight: "15px", color: "#0b0ff1c7" }} text />
                :
                <Button icon="pi pi-plus" label="Add User" onClick={() => { setVisible(true); fetchAllRoles() }} style={{ marginRight: "10", height: "42px", padding: "3px", backgroundColor: "#0b0ff1c7", borderColor: "#0b0ff1c7" }} raised />
            }
            <Dialog header={editUserId ? "Edit User" : "Add User"} visible={visible} onHide={handleClose} style={{ minWidth: "38vw", width: "38vw", zIndex: 1000 }}>
                {/* <hr /> */}
                <Box sx={{ padding: 2, paddingTop: 1 }}>
                    <FormControl component="fieldset" fullWidth sx={{ width: "100%" }}>
                        <FormLabel component="legend" sx={{ fontSize: '0.6vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                            Prefix
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="prefix"
                            name="prefix"
                            value={formData.prefix}
                            onChange={(e) => handleInputChange(e, 'prefix')}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '0.6vw' // Adjust font size of radio labels
                                }
                            }}
                        >
                            <FormControlLabel value="Mr" control={<Radio sx={{ padding: '0 8px' }} />} label="Mr" />
                            <FormControlLabel value="Mrs" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Mrs" />
                            <FormControlLabel value="Miss" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Miss" />
                            {/* <FormControlLabel value="Other" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Other" /> */}
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap", mt: 3 }}>

                        <TextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            sx={{ width: "31.5%" }}
                            onChange={(e) => handleInputChange(e, 'firstName')}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="middleName"
                            name="middleName"
                            label="Middle Name"
                            sx={{ ml: 2, width: "31.5%" }}
                            value={formData.middleName}
                            onChange={(e) => handleInputChange(e, 'middleName')}
                            error={!!errors.middleName}
                            helperText={errors.middleName}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            sx={{ ml: 2, width: "31%" }}
                            value={formData.lastName}
                            onChange={(e) => handleInputChange(e, 'lastName')}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        {/* <TextField
                            fullWidth
                            id="username"
                            name="username"
                            label="Username"
                            value={formData.username}
                            sx={{ ml: 2, width: "23%" }}
                            onChange={(e) => handleInputChange(e, 'username')}
                            error={!!errors.username}
                            helperText={errors.username}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        /> */}
                        <TextField
                            error={!!errors.dateOfBirth}
                            fullWidth
                            type="date"
                            sx={{ mt: 3, width: "48.2%" }}
                            id="dateOfBirth"
                            label="Date of Birth"
                            value={formData.dateOfBirth}
                            onChange={(e) => handleInputChange(e, 'dateOfBirth')}
                            helperText={errors.dateOfBirth}
                            required
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                    width: "98.9%"
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                sx: {
                                    fontSize: '13px', // Adjust the label font size as needed
                                },
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={formData.email}
                            sx={{ width: "48.2%", mt: 3, ml: 2 }}
                            onChange={(e) => handleInputChange(e, 'email')}
                            error={!!errors.email}
                            helperText={errors.email}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="mobile"
                            name="mobile"
                            label="Mobile"
                            value={formData.mobile}
                            sx={{ width: "35%", mt: 3 }}
                            onChange={(e) => handleInputChange(e, 'mobile')}
                            error={!!errors.mobile}
                            helperText={errors.mobile}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="panNumber"
                            name="panNumber"
                            label="PAN Number"
                            sx={{ width: "36%", ml: 2, mt: 3 }}
                            value={formData.panNumber}
                            onChange={(e) => handleInputChange(e, 'panNumber')}
                            error={!!errors.panNumber}
                            helperText={errors.panNumber}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <FormControl
                            sx={{ width: "23%", ml: 2, mt: 3 }}
                            error={!!errors.gender}
                        >
                            <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="roleTypeOption-label">
                                Role
                            </InputLabel>
                            <Select
                                labelId="roleTypeOption-label"
                                id="roleTypeOption"
                                name="roleTypeOption"
                                value={formData.roleId}
                                onChange={(e) => handleRIdChange(e.target)}
                                label="Role"
                                sx={{
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                    // zIndex:1001
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            fontSize: '0.6vw',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    RIdOptions?.map((option) => (
                                        <MenuItem value={option.id}>
                                            <em>{option.roleName}</em>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText sx={{ fontSize: '12px' }}>
                                {errors.roleTypeOption}
                            </FormHelperText>
                        </FormControl>
                        {formData.roleId == 1 &&
                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%", mt: 3 }}>
                                {/* Aadhaar Card Number Input */}
                                <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <TextField
                                        fullWidth
                                        id="aadharNumber"
                                        name="aadharNumber"
                                        label="Aadhaar Card Number"
                                        type="number"
                                        value={formData.aadharNumber}
                                        onChange={(e) => handleInputChange(e, 'aadharNumber')}
                                        error={!!errors.aadharNumber}
                                        sx={{ width: "81%" }}
                                        helperText={errors.aadharNumber}
                                        InputProps={{
                                            sx: {
                                                height: '45px',
                                                fontSize: '0.6vw',
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px',
                                            }
                                        }}
                                    />
                                </Box>
                                <div className='d-flex flex-column' style={{ width: "32%" }}>
                                    <input
                                        type="file"
                                        id="aadharImage"
                                        onChange={(e) => handleImageChange(e, 'aadharImage')}
                                        accept="image/*"
                                    />
                                </div>

                            </Box>
                        }
                        {formData.roleId == 1 && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%", mt: 3 }}>
                                {/* Driving License Number Input */}
                                <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <TextField
                                        fullWidth
                                        id="licenseNumber"
                                        name="licenseNumber"
                                        label="Driving License Number"
                                        value={formData.licenseNumber}
                                        onChange={(e) => handleInputChange(e, 'licenseNumber')}
                                        error={!!errors.licenseNumber}
                                        sx={{ width: "81%" }}
                                        helperText={errors.licenseNumber}
                                        InputProps={{
                                            sx: {
                                                height: '45px',
                                                fontSize: '0.6vw',
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px',
                                            }
                                        }}
                                    />
                                </Box>
                                <div className='d-flex flex-column' style={{ width: "32%" }}>
                                    <input
                                        type="file"
                                        id="licenseImage"
                                        onChange={(e) => handleImageChange(e, 'licenseImage')}
                                        accept="image/*"
                                    />
                                </div>
                            </Box>
                        )}
                    </Box>
                </Box>
                <DialogActions>
                    <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                    <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{editUserId ? "Update" : "Create"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
