import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { url } from '../../../_helper/Route/Url';
import { Tag } from 'primereact/tag';
import AddUser from './AddUser';
import { Button } from 'primereact/button';
import Permissions from './Permissions';

export default function Users() {
    const toast = useRef();
    const [render, setRender] = useState(false);
    const [users, setUsers] = useState([]);

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center" style={{ display: "flex", justifyContent: "center" }}>
                <AddUser refreshData={refreshData} />
                {/* <h4 className="m-0">Customers</h4> */}
            </div>
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case true:
                return 'Active';

            case false:
                return 'Inactive';
        }
    };
    const refreshData = () => {
        setRender(!render)
    }

    useEffect(() => {
        fetchAllUsers()
    }, [render])
    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status ? "Active" : "Inactive"} onClick={() => confirmStatusChange(rowData.id, rowData.status)} style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "darkgreen" : "red" }} severity={getSeverity(rowData.status)} />;
    };

    const confirmStatusChange = (id, status) => {
        confirmDialog({
            message: `Are you sure you want to make it ${!status ? "Active" : "Inactive"}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => changeStatus(id, status),
        });

    }

    const changeStatus = async (id, status) => {
        try {
            const response = await fetch(url + "/api/sup/user/status/update?id=" + id, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({ status: !status }),
            });
            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
            } else {
                toast.current.show({ severity: 'success', summary: 'Status changed successfully!!', life: 3000 })
                refreshData();

            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
        }
    }
    const fetchAllUsers = () => {
        fetch(url + '/api/sup/user/getAll').then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setUsers(result)
                    //console.log(result)
                })
            }
        })
    }

    useEffect(() => {
        fetchAllUsers()
    }, [])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions-buttons" style={{ display: "flex" }}>
                {/* <Permissions roleId={rowData.id} /> */}
                <AddUser refreshData={refreshData} isUpdate={rowData.id} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => confirmDelete(rowData.id)}
                    style={{ width: "20px", height: "20px", marginLeft: "15px", fontSize: "5px" }}
                    aria-label="Delete"
                />
            </div>
        );
    };

    const confirmDelete = (id) => {
        confirmDialog({
            message: `Are you sure you want to delete it?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(id),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }
    const handleDelete = async (id) => {

        try {
            fetch(`${url}/api/sup/user/delete?id=${id}`, {
                method: 'DELETE',
            }).then((response) => {
                if (response.ok) {
                    toast.current.show({ severity: 'success', summary: 'User Deleted Successfully!!', life: 3000 });
                    setRender(!render)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed to delete User', life: 3000 });
                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Failed to delete User', life: 3000 });
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to delete User', life: 3000 });
        }
    };

    const permissionBodyTemplate = (rowData) => {
        return <Permissions roleId={rowData.id} />
    };

    const header = renderHeader();
    return (
        <>
            <Breadcrumbs mainTitle="Users" parent="General" title="Users" />
            <div className="card">
                <Toast ref={toast} />
                <DataTable
                    value={users}
                    header={header}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    rowClassName={() => 'datatable-row'}
                    removableSort
                    dataKey="id"
                    emptyMessage="No users found."
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >
                    <Column field="id" header="UserId" sortable style={{ fontSize: "14px", minWidth: '5rem' }} />
                    <Column field="name" header="Name" sortable style={{ fontSize: "14px", minWidth: '10rem' }} />
                    <Column field="email" header="Email" style={{ fontSize: "14px", minWidth: '5rem' }} />
                    <Column field="roleName" header="Role" style={{ fontSize: "14px", minWidth: '5rem' }} />
                    <Column field="permission" header="Permissions" style={{ fontSize: "14px", minWidth: '3rem' }} align={"center"} body={permissionBodyTemplate} />
                    <Column field="status" header="Status" style={{ fontSize: "14px", minWidth: '6rem' }} body={statusBodyTemplate} />
                    <Column header={"Actions"} headerStyle={{ fontSize: "14px", minWidth: '3rem' }} body={actionBodyTemplate} />
                </DataTable>
                <ConfirmDialog />
            </div>
        </>
    )
}
