import React, { useEffect, useRef,useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { url } from "../Constant";
import { Toast } from 'primereact/toast';

const AuthCheckLoggedIn = ({ children }) => {
    const navigate = useNavigate();

    const toast = useRef(null);

    const [isVerified, setIsVerified] = useState(null);
    const location = useLocation();
    const currentPath = location.pathname; // Get the current route path
    useEffect(() => {
        const verifyUser = async () => {
            try {
                const token = localStorage.getItem("token");
                const role = localStorage.getItem("role");

                if (token && role) {
                    const response = await fetch(`${url}/auth/validate`, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ role })
                    });

                    if (response.ok) {
                        const result = await response.json();

                        if (result.isVerified) {
                            setIsVerified(true);
                            // toast.current.show({ severity: 'error', summary: 'Failed to update Company Profile', life: 3000 });
                            navigate(`/sup/dashboard`,);  // Redirect to the current route path if user is verified
                        } else {
                            setIsVerified(false);
                        }
                    } else {
                        setIsVerified(false);
                    }
                } else {
                    setIsVerified(false);
                }
            } catch (error) {
                console.error("Error verifying user:", error);
                setIsVerified(false);
            }
        };
        verifyUser();
    }, []); 

    useEffect(() => {
        if (isVerified === false && currentPath !== (`/login`)) {
            navigate(`/login`,);
        }
    }, [isVerified, currentPath, navigate]);

    if (isVerified === null) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress sx={{ height: 10 }} />
                <Toast ref={toast} />
            </Box>
        );
    }
    return isVerified ? null : children;
};
export default AuthCheckLoggedIn;