import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { TextField, Box, InputLabel, FormControl, FormHelperText, Select, MenuItem } from '@mui/material';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';


const initialFormData = {
    id: '',
    username: '',
    cname: '',
    contactPerson: '',
    lastName: '',
    email: '',
    phone: '',
    prefix: '',
    roleId: '',
    role: '',
    permission: '',
    details: '',
    aadharImage: null,
    licenseImage: null,
    dateOfBirth: ''
};

export default function EditCustomerProfile() {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    let contactPersonObject = {
        phone: '',
        contactPerson: '',
        email: '',
    }

    const [contactPersons, setContactPersons] = useState([{ ...contactPersonObject }])
    const toast = useRef(null);
    const urlParams = new URLSearchParams(window.location.search);
    const [disabled, setDisabled] = useState(false);
    const userId = urlParams.get('id');
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomerData()
    }, [])

    const fetchCustomerData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/getCustomerById?cid=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                }
                throw new Error('Network response was not ok.');
            }

            const result = await response.json();
            console.log(result, "-------------------");
            setFormData(result); // Update your state with the fetched customer data
            setContactPersons(result.contactPerson);
        } catch (error) {
            console.error('Fetch error:', error);
            // Optionally show an error toast
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        }
    };

    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleContactPersoninputChange = (e, field, index) => {
        setContactPersons(prevContactPersons =>
            prevContactPersons.map((_, i) => {
                if (i == index) {
                    return { ...prevContactPersons[i], [field]: e.target.value };
                }
                return prevContactPersons[i];
            })
        );
    }

    const addMoreContactPerson = () => {
        setContactPersons(prevContactPersons => [
            ...prevContactPersons,
            { ...contactPersonObject }
        ]);
    };

    const removeContactperson = (index) => {
        setContactPersons(prevContactPersons =>
            prevContactPersons.filter((_, i) => i !== index)
        );
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.prefix) newErrors.prefix = "Field cannot be empty";
        if (!formData.cid) newErrors.cid = "This field is required";
        if (!formData.cname) newErrors.cname = "Field cannot be empty";

        if (!formData.status) newErrors.status = "Field cannot be empty";
        if (!formData.address) newErrors.address = "Field cannot be empty";
        if (!formData.city) newErrors.city = "Field cannot be empty";
        contactPersons.map((contactPerson, index) => {
            newErrors.contactPerson = newErrors.contactPerson || [];
            newErrors.contactPerson[index] = newErrors.contactPerson[index] || {};

            if (!contactPerson.contactPerson) {
                newErrors.contactPerson[index].contactPerson = "Field cannot be empty";
            }
            if (!contactPerson.email) {
                newErrors.contactPerson[index].email = "Field cannot be empty";
            }
            if (!contactPerson.phone) {
                newErrors.contactPerson[index].phone = "Field cannot be empty";
            }
        })
        const hasErrors = newErrors.contactPerson.some(error =>
            error.contactPerson || error.email || error.phone
        );

        // If there are no errors, remove the contactPerson key from newErrors
        if (!hasErrors) {
            delete newErrors.contactPerson; // Remove the key if no errors
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        setErrors(formErrors);
        let formDataToBeSent = formData;
        formDataToBeSent = { ...formDataToBeSent, contactPerson: contactPersons }
        try {
            const response = await fetch(`${url}/api/admin/addCustomer`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(formDataToBeSent),

            });

            // Handle different response statuses
            if (response.ok) {
                const data = await response.json();
                console.log('Data submitted successfully:', data);
                setFormData(initialFormData);
                setErrors({});
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Customer added successfully', life: 3000 });
            } else {
                const errorData = await response.json();
                switch (response.status) {
                    case 400:
                        toast.current.show({ severity: 'error', summary: 'Validation Error', detail: errorData.message || 'Invalid input data', life: 3000 });
                        break;
                    case 403:
                        toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You do not have permission to perform this action', life: 3000 });
                        break;
                    case 500:
                        toast.current.show({ severity: 'error', summary: 'Server Error', detail: 'An error occurred on the server', life: 3000 });
                        break;
                    default:
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred', life: 3000 });
                        break;
                }
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };
    return (
        <>
            <Breadcrumbs mainTitle="Edit Customer Profile" parent="Setup" title="Edit" subParent="Customer" subParentTwo="Profile" />

            <Toast ref={toast} />
            <div className="row">
                <div className='col-md-12' style={{ marginTop: 5 }}>
                    <form>
                        <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                            <h5>Basic Details</h5>
                            <div className="row" style={{ marginTop: 25, paddingLeft: "1%", paddingRight: "1%" }}>

                                <FormControl
                                    sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                    disabled={disabled}
                                // error={!!errors.gender}
                                >
                                    <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="prefix-label">
                                        Prefix
                                    </InputLabel>
                                    <Select
                                        disabled={disabled}
                                        labelId="prefix-label"
                                        id="prefix"
                                        value={formData.prefix}
                                        name="prefix"
                                        label="Prefix"
                                        sx={{
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                            // zIndex:1001
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '0.6vw',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="M/s">
                                            <em>M/s</em>
                                        </MenuItem>
                                        <MenuItem value="Mr">
                                            <em>Mr</em>
                                        </MenuItem>
                                        <MenuItem value="Miss">
                                            <em>Miss</em>
                                        </MenuItem>
                                        <MenuItem value="Miss">
                                            <em>Miss</em>
                                        </MenuItem>
                                        <MenuItem value="Other">
                                            <em>Other</em>
                                        </MenuItem>

                                    </Select>
                                    <FormHelperText sx={{ fontSize: '12px' }}>
                                        {errors.prefix}
                                    </FormHelperText>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    disabled={disabled}
                                    id="cname"
                                    name="cname"
                                    label="Company Name"
                                    value={formData.cname}
                                    sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                    onChange={(e) => handleInputChange(e, 'cname')}
                                    error={!!errors.cname}
                                    helperText={errors.cname}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="city"
                                    name="city"
                                    disabled={disabled}
                                    label="City"
                                    sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                    value={formData.city}
                                    onChange={(e) => handleInputChange(e, 'city')}
                                    error={!!errors.city}
                                    helperText={errors.city}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    disabled={disabled}
                                    label="Adddress"
                                    sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                    value={formData.address}
                                    onChange={(e) => handleInputChange(e, 'address')}
                                    error={!!errors.address}
                                    helperText={errors.address}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />

                                <FormControl
                                    sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                    error={!!errors.status}
                                    disabled={disabled}
                                >
                                    <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="status-label">
                                        Status
                                    </InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => handleInputChange(e, 'status')}
                                        label="Status"
                                        disabled={disabled}
                                        sx={{
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                            // zIndex:1001
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '0.6vw',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"Active"}>
                                            <em>Active</em>
                                        </MenuItem>
                                        <MenuItem value={"Inactive"}>
                                            <em>Inactive</em>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText sx={{ fontSize: '12px' }}>
                                        {errors.status}
                                    </FormHelperText>
                                </FormControl>

                            </div>
                        </div>
                    </form>
                    <form className='mt-3'>
                        <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5>Contact Persons</h5>
                                <Button onClick={() => addMoreContactPerson()} icon="pi pi-plus" label="Add More Contact Persons" style={{ fontSize: "0.6vw", marginTop: "1%", marginLeft: "1.5%" }} raised type='button' />
                            </div>
                            {
                                contactPersons.map((item, index) => (
                                    <div key={index} className="row" style={{ marginTop: 25, paddingLeft: "1%", paddingRight: "1%" }}>
                                        <div
                                            style={{
                                                border: "2px solid #a0a0a0",
                                                width: "35px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "35px",
                                                borderRadius: "50%",
                                                color: "#a0a0a0",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                marginLeft: "1%",
                                                marginTop: "1%",
                                                cursor: "pointer"
                                            }} >
                                            {index+1}
                                        </div>
                                        <TextField
                                            fullWidth
                                            id="contactPerson"
                                            disabled={disabled}
                                            name="contactPerson"
                                            label="Contact Person Name"
                                            sx={{ marginLeft: "1%", width: "24%", mt: 2 }}
                                            value={item.contactPerson}
                                            onChange={(e) => handleContactPersoninputChange(e, 'contactPerson', index)}
                                            error={!!errors.contactPerson?.[index]?.contactPerson}
                                            helperText={errors.contactPerson?.[index]?.contactPerson}
                                            InputProps={{
                                                sx: {
                                                    height: '45px', // Adjust the height as needed
                                                    fontSize: '0.6vw', // Adjust the font size as needed
                                                    borderRadius: 3,
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: '0.6vw',
                                                    zIndex: 1
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                sx: {
                                                    fontSize: '12px', // Adjust the helper text font size as needed
                                                }
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            disabled={disabled}
                                            label="Email"
                                            value={item.email}
                                            sx={{ width: "24%", marginLeft: "1%", mt: 2 }}
                                            onChange={(e) => handleContactPersoninputChange(e, 'email', index)}
                                            error={!!errors.contactPerson?.[index]?.email}
                                            helperText={errors.contactPerson?.[index]?.email}
                                            InputProps={{
                                                sx: {
                                                    height: '45px', // Adjust the height as needed
                                                    fontSize: '0.6vw', // Adjust the font size as needed
                                                    borderRadius: 3,
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: '0.6vw',
                                                    zIndex: 1
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                sx: {
                                                    fontSize: '12px', // Adjust the helper text font size as needed
                                                }
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            label="Mobile"
                                            disabled={disabled}
                                            value={item.phone}
                                            sx={{ width: "24%", mt: 2, marginLeft: "1%" }}
                                            onChange={(e) => handleContactPersoninputChange(e, 'phone', index)}
                                            error={!!errors.contactPerson?.[index]?.phone}
                                            helperText={errors.contactPerson?.[index]?.phone}
                                            InputProps={{
                                                sx: {
                                                    height: '45px', // Adjust the height as needed
                                                    fontSize: '0.6vw', // Adjust the font size as needed
                                                    borderRadius: 3,
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: '0.6vw',
                                                    zIndex: 1
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                sx: {
                                                    fontSize: '12px', // Adjust the helper text font size as needed
                                                }
                                            }}
                                        />
                                        {
                                            index > 0 &&
                                            <div
                                                onClick={() => removeContactperson(index)}
                                                style={{
                                                    border: "2px solid red",
                                                    backgroundColor: "red",
                                                    width: "21px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "21px",
                                                    borderRadius: "50%",
                                                    color: "#fff",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    marginLeft: "1%",
                                                    marginTop: "1.5%",
                                                    cursor: "pointer"
                                                }} >
                                                X
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </form>
                    <div className='d-flex justify-content-end mt-3 mr-5'>
                        <Button
                            severity="danger"
                            style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }}
                            color="error"
                            onClick={() =>
                                navigate(-1)}
                        >
                            Cancel
                        </Button>
                        <Button severity='primary' onClick={() => handleSubmit()} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold", marginLeft: "1%" }} type="submit">Submit</Button>
                    </div>
                </div>
                {/* <div className='col-md-4' style={{ marginTop: 30 }}>
                    <ChangeUserPassword />
                </div> */}
            </div>
        </>
    )
}
