import React, { useRef,useState, useContext } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../AbstractElements';
import { ForgotPassword, Password, SignIn, Username,url } from '../Constant';
import { useNavigate } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import CustomizerContext from '../_helper/Customizer';
import { InputGroup, InputGroupText } from 'reactstrap';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Toast } from 'primereact/toast';
import AuthCheckLoggedIn from '../Authcheck/AuthCheckLoggedIn';


const Logins1 = () => {
  const toast = useRef(null);
  const [formValues, setFormValues] = useState({
    userName: '',
    password: ''
  });

  const [errors, setErrors] = useState({
    userName: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const validate = () => {
    const newErrors = {
      userName: formValues.userName === '' ? 'User Name is required' : '',
      password: formValues.password === '' ? 'Password is required' : ''
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error !== '');
  };

  const handleChange = (e) => {
    let { id, value } = e.target;
    if(id == "userName")value = value.slice(0,13);

    setFormValues({
      ...formValues,
      [id]: value
    });

    setErrors({
      ...errors,
      [id]: value.trim() === '' ? `${id.charAt(0).toUpperCase() + id.slice(1)} is required` : ''
    });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${url}/auth/login/userLoginWithoutCmpanyCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userName: formValues.userName,
          password: formValues.password
        })
      });
      const result = await res.json();
      if (res.ok) {
        localStorage.setItem('token', result.token);
        localStorage.setItem('role', result.role);
        localStorage.setItem('login', JSON.stringify(true));
        navigate(`/panel/dashboard`);
      }
      else if(res.status === 401){
        if(result.error==="Company Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        else if(result.error==="User Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          userName: result.error === "Invalid username" ? "Invalid User Name" : prevErrors.userName,
          password: result.error === "Invalid password" ? "Invalid Password" : prevErrors.password
        }));
      }
      }
      catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleLogin();
    }
  };

  return (
    <AuthCheckLoggedIn>
    <Container fluid={true} className='p-0 login-page '>
      <Toast ref={toast} />
      <div className='login-card'>
      <div className='login-main login-tab'>
      <Row>
        <Form className='form-bookmark needs-validation'>
          <H4>Sign In</H4>
          <P>Enter your username & password to login</P>
          {/* User Name Field */}
          <FormGroup>
            <Label className='col-form-label'>{Username}</Label>
            <Input
              className={errors.userName === "" ? 'form-control' : 'form-control is-invalid'}
              type='email'
              id='userName'
              value={formValues.userName}
              onChange={handleChange}
            />
            <span style={{ fontSize: "15px" }} className="text-danger">{errors.userName}</span>
          </FormGroup>
          {/* Password Field */}
          <Label>{Password}</Label>
          <InputGroup>
              <Input className={errors.password === "" ? 'form-control' : 'form-control is-invalid'} id='password' value={formValues.password} onChange={handleChange} type={togglePassword?"text":"password"} placeholder=""  aria-label="Recipient's username" />
              <InputGroupText className='p-0 m-0'>
              <Button
                className='my-auto'
                variant="contained"
                disableElevation
                onClick={() => setTogglePassword(!togglePassword)}
                startIcon={togglePassword ? <VisibilityOff /> : <Visibility />}
                style={{
                  backgroundColor: 'transparent',
                  color: 'inherit', // Inherits the color from the parent or default text color
                  paddingRight: 0,
                  minWidth: 'auto',
                  height:"auto",
                  boxShadow: 'none',
                  border: "none",
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
              />
            </InputGroupText>
          </InputGroup>
          <span style={{ fontSize: "15px" }} className="text-danger">{errors.password}</span>
          {/* <FormGroup className='position-relative'>
            <Label className='col-form-label'>{Password}</Label>
            <div className='position-relative'>
              <Input
                className={errors.password === "" ? 'form-control' : 'form-control is-invalid'}
                type={togglePassword ? 'text' : 'password'}
                id='password'
                value={formValues.password}
                onChange={handleChange}
              />
              <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                <span className={togglePassword ? '' : 'show'}></span>
              </div>
            </div>
            <span style={{ fontSize: "15px" }} className="text-danger">{errors.password}</span>
          </FormGroup> */}
          {/* Buttons */}
          <div className='form-group mb-3'>
            <Label style={{ margin: "4% 0%" }}>
              <a className='link' href='#javascript'>{ForgotPassword}</a>
            </Label>
            <Btn
              attrBtn={{
                color: 'primary',
                className: 'd-block w-100 mt-2',
                type: 'submit',
                onClick: handleSubmit,
                disabled: loading
              }}
            >
              {SignIn}
            </Btn>
          </div>
        </Form>
      </Row>
      </div>
      </div>
    </Container>
    </AuthCheckLoggedIn>
  );
};

export default Logins1;